<template>
  <div class="box">
    <img src="@/assets/images/logo.png"  alt="">
    <br> 
    <p class="text-center text_info">Enter Your Number</p>
    <div class="input">
      <input type="text" placeholder="+964" class="game_input" v-model="phone" @input="valueChanged" />
    </div>

    <div class="mt-4 butn">
      <button :class="{ game_btn: true, activeBtn: isChanged }" id="sub_btn" :disabled="btnDisabled"
        @click="generateScript()">{{ btnDisabled ? "Loading ..." : "Subscribe" }}</button>
    </div>

    <div class="desc mt-4">
      <p class="text-center text-white">
        Welcome to Her Universe! You will receive 3 points on a daily basis for the price of 300 IQD/day. To unsubscribe, send
        0 to 3836 (Free of charge).
      </p>
    </div>
  </div>
</template>

<script>
import AlertService from '@/services/errors'
import ApiService from '@/services/api'
import { v4 as uuidv4 } from "uuid"

export default {
  data () {
    return {
      prefixPHone: '+964',
      phone: '+964',
      isChanged: false,
      btnDisabled: false
    }
  },
  methods: {
    valueChanged (Val) {
      this.isChanged = true
    },
    async generateScript () {
      this.btnDisabled = true
      var phoneNumber = '964' + this.phone.replaceAll('+964', '')
      if (phoneNumber.length < 7) {
        AlertService.errorMessage("Invalid Phone")
        this.btnDisabled = false
        return
      }
      var uuid = "Game-Expert-App-" + uuidv4()
      var timestamp = this.getTimeStamp()
      this.$store.commit('setPhoneNumber', phoneNumber)
      this.$store.commit('setTi', uuid)
      this.$store.commit('setTS', timestamp)
      const response = await ApiService.generateScript(
        uuid,
        timestamp
      )
      if (response && response.status === 200) {
        var s = response.data.s
        const script = document.createElement('script')
        script.type = 'text/javascript'
        script.text = s
        document.head.appendChild(script)
        const ev = new Event('DCBProtectRun')
        document.dispatchEvent(ev)
        await this.sendSMS()
      } else {
        this.btnDisabled = false
        this.isLoading = false
      }
    },
    async sendSMS () {
      const phoneNumber = this.$store.state.phoneNumber
      const ti = this.$store.state.ti
      const response = await ApiService.login(phoneNumber, ti)
      this.isLoading = false
      if (response && response.status === 200) {
        this.$router.push('/verify')
      } else {
        this.btnDisabled = false
        this.errorMessage('Error')
      }
    },
    getTimeStamp () {
      return parseInt(new Date().getTime() / 1000)
    }
  },
  mounted () {
    this.$i18n.locale = localStorage.getItem("lang") ?? "en"
    console.log(this.$i18n.locale)
  }
}
</script>

<style>

img{
  
  width: 348.75px;
  height: 181.16px;
  
}

</style>
